
<!-- tenho que zerar o vetor para não chaves duplicadas   -->
<template>
<div>
    <b-table-simple>
      <b-thead id="b-head">
        <b-tr>
        
          <b-th class="b-th">Cod.Prod</b-th>
          <b-th class="b-th">Referencia</b-th>
          <b-th class="b-th">Classificação</b-th>
          <b-th class="b-th">Quantidade</b-th>
          <b-th class="b-th">unidade</b-th>
          <b-th class="b-th">Custo R$</b-th>
          <b-th class="b-th">Cosumo</b-th>
          <b-th>&nbsp;</b-th>
          <b-th>&nbsp;</b-th>
          <b-th>&nbsp;</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(item, index) in receive_composition" :key="index=item.produto_id">
          <td>
           {{ index }}
          </td>
          <b-td>
            <input v-model="item.produto" type="text" class="form-control text-left"   >
          </b-td>
          <b-td>
            <input v-model="item.produto" type="text" class="form-control text-left"   >
          </b-td>
          <b-td>
            <input v-model="item.quantidade" type="text" class="form-control text-left"   >
          </b-td>
          <b-td>
            <input v-model="item.produto_id" type="text" class="form-control text-left"   >
          </b-td>
          <b-td>
            <input v-model="item.custo" type="text" class="form-control text-left"   >
          </b-td>
          <b-td>
            <input v-model="item.venda" type="text" class="form-control text-left"   >
          </b-td>
        </b-tr>
      </b-tbody>

    </b-table-simple>

  </div>
</template>

<script>
export default {
    name: "composicao",
    props: {
        produto: { type: Object },
        composicoes:{type: Array}

    },
    data() {
        return {
            prod: '',
            items1:[
        {
           id: 1,
           nome:'ola'
        },
        {
          id: 2,
           nome:'ol2'
        },
         
        {
          id: 3,
          nome:'ol3'
        },
      ],

      receive_composition:[]
        }
    },
    mounted() {
        this.prod = this.produto
        // recebe as composiçeos de acordo com o id do produto;
        this.receive_composition = this.composicoes

    }

}
</script>

<style></style>